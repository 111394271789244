/* .gb-grid-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.gb-grid-wrapper-74f826f4 > .gb-grid-column {
    padding-left: 0px;
}
.gb-grid-wrapper > .gb-grid-column-cba70c1c {
    width: 25%;
}

.gb-grid-column {
    box-sizing: border-box;
}
.gb-grid-wrapper > .gb-grid-column > .gb-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.gb-container-cba70c1c {
    background-color: rgba(255, 255, 255, 0.5);
    border-style: solid;
    border-width: 1px 1px 1px 0;
    border-color: #4A5568;
}
.gb-container-cba70c1c > .gb-inside-container {
    padding: 0;
}
.gb-container-cbc30cf7 {
    margin: 0;
    background-color: #054d80;
}
.gb-container-cbc30cf7 > .gb-inside-container {
    padding: 10px 0;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}
h4.gb-headline-b1a9b674 {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 100;
}
.gb-container-2eb98a68 {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #dfe6ed;
}
.gb-container-2eb98a68 > .gb-inside-container {
    padding: 0 0 10px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}
.gb-container-3fbb5bb1 > .gb-inside-container {
    padding: 20px 6px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
} */

.invalidFilter button {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  pointer-events: none;
  cursor:not-allowed;
}

#NotificationModal .modal-header{
    border-bottom: 0px solid #dee2e6;
}
#NotificationModal .modal-title{
   margin: auto;
  left: 0px;

}
#NotificationModal  .modal-header .close {
    padding: 0rem;
    margin: 0;
}
.deputyRole{
    margin-top: -1em !important;
}
.simple-list-item:hover{
    cursor: pointer;
    background-color: #dddddd;
}
.searchStats.table thead th{
    vertical-align: middle !important;
}
.TAMTheme .fa-info-circle{
    color: white;
}
.TAMButton{
    background-color: #f5733e !important;
    color:white;
    padding: 0em 1em;
    font-weight: 700;
    cursor: default !important;
}
.TAMButton:hover{
    color: white ;
}
.MuiBadge-badge{
    z-index: 0 !important;
}
.TAMExlucdetxt{
    font-size: 0.7em;
    margin-top: 1em;
}
.TAM-button-small-text{
    font-size: 11px;
}
.white-bg{
    background-color: white;
    padding:1em;
    margin: 0.5em;
    color:black;
}
.num-gov {
    margin-bottom: 4em;
}
.progress{
   height: 2rem;
}
.TAMTheme{
    
}
.TAMcontent{

}
.MuiBadge-colorError{
    background-color:#5dd3d3 !important;
}
.TAMcontent .MuiIconButton-label {
    color: #f5733e !important;
}
.search-govblock{
    margin-bottom: 3em;
    margin-top:2.8em;
}
 .TAMTheme .MuiAccordion-root{
    box-shadow: 0px 2px 1px -1px rgb(255 255 255), 0px 1px 1px 0px rgb(255 255 255), 0px 1px 3px 0px rgb(255 255 255);
}

 .TAMTheme .MuiAccordion-root , .TAMTheme .searchResults, .TAMTheme .table, .TAMTheme .select-all , .TAMTheme .deselect-all{
    background-color: #044d7f;
    color:white;
}
.TAMTheme .search-gov .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(128 128 128 / 46%);
}
 .TAMTheme .noUi-pips{
     color:white;
 }
 .TAMTheme  .noUi-marker-large {
    background: white;
}
.TAMTheme  .accordianLine {
    border-bottom: 1px solid rgb(255 255 255);
}

.TAMTheme   .disableFilter   .MuiAccordion-root{
  background-color: #dddddd !important;
  color:black !important;
}
.tam-new-text-big {
    font-size: 1.5em !important;
    color: #FF753F !important;
}
  
.progress-bar.bg-info{
     background-color: #044d7f !important;
}
    
.custom-badge{

    padding: .50em .4em;
    font-size: 1em;

}
.roleCheckboxes .MuiFormControlLabel-label.Mui-disabled{
    color: rgba(0, 0, 0, 3);
}
.white-color{
    color:white !important;
}
#LoadSavedSearch-label{
    color: black;
}
sup .fa{
    font-size: small !important;
}
#governmentRoles sup .fa{
    font-size: 1em !important;
}
.disabledCheckBox{
    pointer-events: none;
}
.MuiFormControl-marginNormal {
    margin: 0 !important;
}
.makeStyles-inputRoot-14 {
    color:black;
}
.MuiInputBase-input{
    color:black !important;
    background-color: white !important;
}
.MuiFormLabel-root {
    background-color: white;
    border-radius: 5px;
    color: black !important;
}
.MuiFormLabel-root.Mui-Focused{
    color:black !important;
}
.MuiFormControlLabel-label{
    font-size: 1em !important
}
.newofficiashelptext{
    color: #044c7e; 
    padding-left: 10px;
    font-size: 13px;
}
.newofficiashelptext2{
    padding-left: 0px;
    font-size: 11px !important;
}
.accordianLine{
    border-bottom: 1px solid rgba(0,0,0,.1);
}
.MuiSlider-root{
    color: #1998ca !important;
}
.PopulationSlider{
    width: 100%;
    margin: 0px 10px;
}
.noUiSlider {
    width: 100%;
    margin: auto auto 50px;
}

.noUiSlider h4 {
    text-align: center;
}

.noUiSlider button {
    margin: 20px;
}
.new-text-small{
    color: #c0392b;
}
.warning {
    width: 100%;
    background-color: #fff;
    color: #c0392b;
    text-align: center;
    margin: auto auto 20px;
}
.noUi-touch-area {
    height: 100%;
    width: 100%;
    cursor: pointer;
}
.noUi-horizontal .noUi-tooltip{
    font-size: 8px;
    padding: 2px;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 7px !important;
}
.MuiAccordion-rounded:first-child{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.MuiAccordion-rounded:last-child, .MuiAccordion-rounded:first-child{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px  !important;
    border-top-left-radius: 0px  !important;
    border-top-right-radius: 0px  !important;
    
}
.PrivateSwitchBase-root-3 {
    padding: 6px !important;
}
.roleLabel{
margin-right: 0px!important;  
}

.roleLabel p{
    font-size: 0.75em;
    line-height: 1.2;
    
}
.MuiAccordionSummary-content.Mui-expanded {
    margin: 5px 0 !important;
}
#SearchLocationZipCode{
    padding: 5px 4px;
}
#SearchLocationZipCode .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 25px;
}
#zipfocusHelptxt1{
    font-size: 0.75em;
    margin-top: 1em;
    text-align: center;
}
.max-height-200 {
    border: 1px solid #eee;
    overflow-y: scroll;
    overflow-x:hidden;
}
.MuiStepIcon-root.MuiStepIcon-completed {
    color: #044d7f  !important;
}
.MuiButton-containedPrimary{
    color: #044d7f  ;
    background-color: #044d7f !important;
}
.MuiStepIcon-root.MuiStepIcon-active{
    color: #044d7f !important;
}

.btn-secondary {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}
.currentPlan {
    cursor: pointer;
    text-transform: uppercase;
    color: #ffffff !important;
    /* background-color: #044d7f !important; */
    border-color: #204d7f;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
    padding: 12px 0;
    /* margin-bottom: 30px; */
    border-radius: 0px;
}

.unavailable{
    color: #fff !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    cursor: not-allowed !important;
}
.get-started{
    cursor: pointer;
    text-transform: uppercase;
    color: #ffffff !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
    padding: 12px 0;
    
    border-radius: 0px;
}
.column-in-center{
    float: none;
    margin: 0 auto;
}
.error {
    color: indianred;
    background-color: #FFF;
}
.download-btn{
    padding:10px !important;
}
.invalid-number{
    border: 1px solid #e00e0e !important;
}
#stripe-modal .form-row {
    display: inherit;
}
#card-errors {
    color: #e25950;
}
#payment-form button, #payment-bank-form button {
    display: block;
    background: #044c7e;
    color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    font-weight: 700;
    width: 100%;
    height: 40px;
    outline: none;
    cursor: pointer;
    transition: all 0.15s ease;
    margin-top: 40px;
}
#payment-form .element-errors {
    display:flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding-left: 20px;
    color: #e25950;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    background-size: 15px 15px;
}
.stripe_card_wrap{
    border: 1px solid #DDD;
    padding: 3%;
    border-radius: 7px;
}
#stripe_modal_body_wrap{
    padding: 0 4% 4%;
}
#stripe_modal_header_wrap{
    border-bottom: none !important;
}
#payment-form button, #payment-bank-form button {
    display: block;
    background: #044c7e;
    color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    font-weight: 700;
    width: 100%;
    height: 40px;
    outline: none;
    cursor: pointer;
    transition: all 0.15s ease;
    margin-top: 40px;
}
.dropdown-menu  button{
    padding: 0rem 1.5rem
}

.searchloader{
width: 3rem; 
height: 3rem;
float: left;
}
.radiusSelect{
    height: calc(1.5em + 0.75rem + 12px) !important;
}
.RequiredPurchase{
    cursor: pointer;
    color:#044d7f;
}

.StripeFormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.StripeFormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.card {
  background-color: white;
  /*padding: 10px 20px 11px;*/
  padding: 0PX !important;
  border-radius: 0px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
.pay-button{
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #556cd6;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}
.pay-button:disabled{
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}
.pay-button:disabled:hover{
  box-shadow: none;
}
.pay-button:hover{
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

.noUi-value.noUi-value-horizontal.noUi-value-large {
     position: absolute;
 }

 .powermaxcost{ 
    font-weight: 600;
    font-size: 34px;
    color: #f5733e !important;
  }

 .search-list-table .MuiTableBody-root td:first-child div {
  border-bottom: 1px  grey !important;
    cursor: pointer  !important;
    width: initial !important;
 }
 .search-list-table .MuiTableBody-root td:first-child div a{
     white-space: nowrap;
 }
.payoption-table th{
text-align: left;
}
.payoption-table td{
text-align: left;
padding: 0.75rem 0;
}

.invoice-items td{
text-align: center;
}

 .editIcon{
     float: right !important;
 }
 .search-list-table .MuiTableBody-root td:first-child i {
  font-weight: 700;
 }
 

 .MuiTableBody-root tr:hover{
   background-color: #f2f2f2;
}
.MuiTableBody-root tr:nth-child(even) {
  background-color: #f2f2f2;
}
/*.search-list-table .MuiInputBase-input{
  display: block;
    width: 100%;
    height: 24px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}*/
.MuiTableSortLabel-root.MuiTableSortLabel-active{
  color: white !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: #fcfcfc !important;
    opacity: 1;
}
/*.MTableToolbar-searchField-10{
  border: 1px solid #757d8d !important;
    border-radius: 5px !important;
}
.search-list-table .MuiInput-underline:after{
  border-bottom: 0px !important;
}*/
.react-tel-input .form-control{
  width: 100% !important;
}
.yoursearch{
    text-transform: initial !important;
}
.yoursearch li{
    font-size: 3em;
}
/* Dropdown Button */
.dropbtn {
  background-color: white;
  color: black;
  padding: 5px;
  padding-left: 15px;;
  font-size: 16px;
  text-align: left;
  height: 44px;
  border: none;
  font-weight: 500;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: white;}

.results{
    max-height: 200px;
    overflow-y: scroll;
}
/*Edit*/
.login-right h1{
    font-weight:500;
}
.loginInput .form-control{
    height: calc(1.5em + .75rem + 12px) !important;
}
.form-control{
    height: calc(1em + .75rem + 12px) !important;
}
.remember-form {
    margin-bottom: 13px;
}
.table .form-control{
    height:calc(1em + .75rem + 12px) !important;
}
.search-govblock h2,
.search-gov h2{
        font-size: 27px;
    font-weight: 600;
    text-align: left;
}
.num-gov{
    padding-right: 33px;
}
.my-search .col-4{
        display: flex;
    align-items: center;
}
.noUi-connect {
    background: #1998ca !important;
}
.MuiTableBody-root td{
    font-size:15px !important;
}
.MuiInput-underline:before{
    /* border-bottom:none !important */
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 2px 4px !important;
}
.my-search {
    padding: 15px 30px;
}
.navbar-expand-lg .navbar-nav .dropdown-menu .nav-link{
    text-align: left;
    font-size: 16px;
    padding: 7px 20px !important;
}
.dropdown-menu button {
    padding: 0rem 0px;
}
.MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px !important;
}
b, strong {
    /* font-weight: bold; */
}
#DownloadInfo p{
    margin-bottom: 0px;
}
.refbtn {
    width: auto;
}
.MuiTypography-body1:hover{
        text-decoration: none;
    /*color: #044d7f;*/
}
.custom-textarea{
  min-height: 90px;
  display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.searchStats  td, .table th {
    padding: .75rem;
}

.swal2-styled.swal2-confirm {
    padding: 6px 12px;
    border: 0;
    border-radius: .25em;
    background: initial;
    background-color: #044d7f;
    color: #fff;
   font-size: 15px;
    text-transform: uppercase;
}
.swal2-styled.swal2-cancel{
    padding: 6px 12px;
    font-size: 15px;
     text-transform: uppercase;
}
.DeleteIcon{
    cursor: pointer;
}
.month12{
    margin-left: auto;
}
.newOfficial .MuiIconButton-colorSecondary{
    margin-top: -1em;
}
.newOfficial .MuiFormControlLabel-label.Mui-disabled{
    color: black !important;
}
.backbtn {
    color: #6c757d  !important;
    border-color: #6c757d !important;
    background-color: #ffffff !important;
}
.backbtn:hover {
   color: #fff  !important;
    background-color: #6c757d  !important;
    border-color: #6c757d  !important;
}
.fixedTableLayout{
    table-layout: fixed;
}
.customOverflow{
    overflow-x: scroll;
}
.customOverflow::-webkit-scrollbar {
  /* width: 10px; */
  height: 5px;
}
.custom-swal2-icon{
    width: 3em;
    height: 3em;
    margin: 1.25em auto 0.875em;
}
.custom-swal2-icon .swal2-x-mark-line-right{
    right: 0.4em;
}
.custom-swal2-icon .swal2-x-mark-line-left{
    left: 0.525em;
}

/* Track */
.customOverflow::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.customOverflow::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.customOverflow::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media screen and (max-width: 767px){
.login-block .login-right h1{
    padding: 28px 0px 0px 0px;
}
.login-right .form-group {
    margin-bottom: 15px;
}
.login-bg img{
    height: auto;
}
.login-block .login-right h1 {
    padding: 0px 0px 0px 0px;
}
.login-block{
    margin-bottom: 5% !important;
}
.login-block .login-right {
    padding: 28px 20px 0px 20px;
}
.refbtn {
    width: 90%;
    height: auto;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(9px, -6px) scale(0.75);
    padding: 5px 15px;
}
.search-govblock h2, 
.search-gov h2{
        font-size: 21px;
    font-weight: 600;
}
.save-new-search{
    margin-top: 10px !important;
}
.num-gov {
    padding-right: 0;
    text-align: center !important;
    padding-bottom: 0;
    padding-left: 0;
}
.my-search .col-4{
        display: flex;
    align-items: center;
    padding-right: 0px;
}
.nav-link {
    padding: 0;
}
.profile-object{
        padding: 1rem 0 .5rem 0;
}
.my-search {
    padding: 12px 0px;
}
.radiusSelect{
        margin-bottom: 15px;
}
.roleLabel p {
    font-size:13px;
}
.MuiAccordionDetails-root {
    padding: 0px 16px 0px !important;
}
#zipfocusHelptxt1{
        padding: 0;
    margin: 0 0 15px;
}
 .MuiAccordion-root.Mui-expanded  {
    padding-bottom: 15px;
}
.MuiAccordionSummary-root.Mui-expanded {
    min-height: 45px;
}
}


@media only screen and (max-width: 375px){
.refbtn {
    width: 138px;
    height: auto;
    padding-right: 17px;
}
}
.inputClearBTN{
    position: absolute;
    right: 26px;
    top: 9px;
    cursor: pointer;
}
.TAMTheme .inputClearBTN{
    color: #000;
}