* {
  /*font-family: 'Roboto', sans-serif;*/
  font-family: "Heebo", sans-serif;
}

.color1 {
  color: #F00;
  background-color: #FF9;
}

.new-text-small {
  /* font-size: 10px !important; */
  color: #FF753F !important
}

.new-text-big {
  font-size: 0.90em !important;
  color: #FF753F !important
}

.my-table-container {
  max-width: 80% !important;
  margin: 0 auto;
  display: table;
  width: 80% !important;
}

.seeMoreLess {
  margin-top: 16px;
  margin-left: 7px;
  font-size: 14px;
  text-decoration: underline;
  color: #044d7f;
  cursor: pointer;
}

/* .form-group {
  margin-bottom: 2rem;
} */

a:link,
a:visited {
  color: #044d7f;
}

a:hover,
a:active {
  color: #076599;
}

.blinking {
  animation: blink 1s linear infinite;
  font-size: 20px;
  color: burlywood;
}

.has-error {
  border-color: red;
}

.refbtn {
  width: 170px;
  height: 63px;
  cursor: pointer;
}

.highlight {
  background-color: #f8d7da !important;
}

.highlight td {
  background-color: #f8d7da !important;
}

.spinner-border {
  margin-left: 50%;
}

.noevent {
  pointer-events: none;
}

.reg-center {
  text-align: center;
  padding-top: 35px;
}

.reg-center h1 {
  color: #044d7f;
  font-weight: 500;
}
.reg-img {
  color: #076599 !important;
  font-size: 21px !important;
}
.left-reg li {
  line-height: 1.8;
  font-size: 21px !important;
  color: #076599 !important;
}

.reg-img {
  /* padding-top: 59px; */
}

body.downloadloading {
  overflow: hidden;
}

body.downloadloading .downloadmodal {
  display: block;
}

.downloadmodal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
  border-radius: 5px;
}

.content {
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
  border-radius: 5px;
  margin-top: 0px;
  text-align: center;
  padding: 25px;
}

.downloadmodal .center {
  /*margin: auto;
  width: 60%;
  top: 50%;
  border: 3px solid #73AD21;
  padding: 10px;*/

  width: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 25%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 50%;
}

#powerentryrenewModal .modal-header {
  padding: 15px 20px 12px;
  background: #044d7f;
  color: #fff;
  text-align: center;
  display: block;
}

#powerentryrenewModal .modal-header h4 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#powerentryrenewModal .modal-header h4 span {
  font-weight: 500 !important;
}

#powerentryrenewModal .modal-body strong {
  text-align: center;
  display: block;
  color: #044d7f;
  font-size: 21px;
  font-weight: 400;
}

#powerentryrenewModal .modal-body {
  padding: 20px 50px;
}

#powerentryrenewModal .modal-body p {
  text-align: left !important;
  font-weight: 300;
  font-size: 16px;
  color: #044d7f;
}

#powerentryrenewModal .modal-body p a {
  font-size: 16px;
  color: #7f7f7f;
  font-weight: 400;
  position: relative;
  padding-left: 20px;
  display: block;
}

#powerentryrenewModal .modal-body p a:before {
  content: "\f111";
  position: absolute;
  left: 0;
  color: #044d7f;
  font-size: 8px;
  font-family: fontawesome;
  top: 6px;
}

#powerentryrenewModal .modal-body p a:hover {
  text-decoration: none !important;
  color: #044d7f;
}

#firstTimePopup .modal-header {
  padding: 15px 20px 12px;
  background: #044d7f;
  color: #fff;
  text-align: center;
  display: block;
}

#firstTimePopup .modal-dialog {
  max-width: 620px !important;
}

#firstTimePopup .modal-header h4 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#firstTimePopup .modal-header h4 span {
  font-weight: 500 !important;
}

#firstTimePopup .modal-body strong {
  text-align: left;
  display: block;
  color: #044d7f;
  font-size: 21px;
  font-weight: 400;
}

#firstTimePopup .modal-body {
  padding: 20px 50px;
}

#firstTimePopup .modal-body p {
  text-align: left !important;
  font-weight: 300;
  font-size: 16px;
  color: #044d7f;
}

#firstTimePopup .modal-body p a {
  font-size: 16px;
  color: #7f7f7f;
  font-weight: 400;
  position: relative;
  padding-left: 20px;
  display: block;
}

#firstTimePopup .modal-body p a:before {
  content: "\f111";
  position: absolute;
  left: 0;
  color: #044d7f;
  font-size: 8px;
  font-family: fontawesome;
  top: 6px;
}

#firstTimePopup .modal-body p a:hover {
  text-decoration: none !important;
  color: #044d7f;
}

#firstTimePopup p button {
  background-color: #044d7f !important;
}

#proceedToRenewPowerentry .modal-header {
  padding: 15px 50px 12px;
  background: #044d7f;
  color: #fff;
  text-align: center !important;
  display: block;
}

#proceedToRenewPowerentry .modal-header h4 {
  font-size: 21px;
  text-align: center !important;
}

#proceedToRenewPowerentry .modal-header h4 span {
  font-weight: 500;
}

#proceedToRenewPowerentry .modal-body {
  padding: 20px 44px;
}

#proceedToRenewPowerentry .modal-body p {
  text-align: left !important;
  display: block;
  color: #044d7f;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
}

#proceedToRenewPowerentry .modal-body p a {
  font-size: 16px;
  color: #7f7f7f;
}

#proceedToRenewPowerentry .modal-body p a:hover {
  text-decoration: none !important;
  color: #044d7f;
}

@keyframes blink {

  /*   0%{opacity: 0;}
    50%{opacity: .5;}*/
  100% {
    opacity: 1;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul.breadcrumb {
  text-transform: uppercase;
  margin: 0px;
  list-style: none;
  background-color: #eee;
}

ul.breadcrumb {
  background-color: #808080;
  padding: 0px;
}

ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}

ul.breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

ul.breadcrumb li a {
  color: #fcfcfc;
  text-decoration: none;
}

ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

.nav-pills>li+li {
  margin-left: 2px;
}

.nav-pills>li {
  float: left;
}

.nav>li {
  position: relative;
  display: block;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  color: #fff;
  background-color: #337ab7;
}

.nav-pills>li>a {
  border-radius: 4px;
}

.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.disableFilter {
  pointer-events: none;
}

.purchaseform table {
  width: 100%;
}

.iframeSrc header {
  display: none !important;
}

.disableFilter .card {
  background-color: #dddddd !important;
  color:black !important;
}

.disableFilter .MuiAccordion-root {
  background-color: #dddddd !important;
}


a.disabled {
  pointer-events: none;
  cursor: default;
}

.confirm-save-btn {
  font-weight: normal !important;
  color: #ffffff;
  background-color: #044d7f;
}

.confirm-cancel {
  font-weight: normal !important;
  color: #ffffff;
  background-color: #808080 !important;
}

.confirm-save-btn:hover {
  background-color: #808080;
}

.et_pb_pricing {
  padding: 30px;
}

.et_pb_pricing ul {
  padding: 0 !important;
  line-height: inherit !important;
  list-style: none !important;
}

.pricing .title {
  font-weight: 600;
  font-size: 34px;
  color: #074c7e !important;
}

.pricing .title2 {
  font-weight: 600;
  font-size: 34px;
  color: #f5733e !important;
}

.pricing .title-sub {
  color: #074c7e !important;
  font-size: 25px;
  font-weight: 700;
  display: inline-block;
  margin-top: 2em;
}

.custom-card {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.card-body{
  background-color: rgba(0,0,0,.03);
}
.custom-card-header {
  background-color: #074c7e;
  color: white;
  padding: 0.7em;
  border-radius: 0 !important;
}

.et_pb_pricing li {
  position: relative;
  padding: 0 0 0em 0px;
  line-height: 1.6em;
}

.et_pb_pricing li span {
  position: relative;
}

.get-started {
  border-radius: 5px !important;
  background-color: #ff753f !important;
  border-color: #ff753f !important;
}

.get-started:hover {
  background-color: #074c7e !important;
  color: white !important;
  border-color: #074c7e !important;
}

.current-plan {
  background-color: #50c5a9 !important;
  border-color: #50c5a9 !important;
  cursor: not-allowed !important;
}
.et_pb_pricing li{
  color: #4a5568;
}
/* .et_pb_pricing li span:before {
  position: absolute;
  top: 9px;
  left: -14px;
  border-width: 3px;
  border-style: solid;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  content: "";
} */

.et_pb_pricing li span:before {
  border-color: #044c7e;
}

.et_pb_pricing .powermax span:before {
  border-color: #f5733e;
}

.confirm-download-text {
  color: #0f88da;
  font-weight: bold;
}

.btn-pa {
  cursor: pointer;
  text-transform: uppercase;
  color: #ffffff !important;
  background-color: #044d7f !important;
  border-color: #204d7f;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 12px 0;
  /*margin-bottom: 30px;*/
  border-radius: 0px;
}

.btn-pa:hover {
  color: #ffffff;
  background-color: #808080;
  border-color: #808080;
}

.btn-register {
  cursor: pointer;
  text-transform: uppercase;
  color: white !important;
  background-color: #db7126 !important;
  border-color: #db7126 !important;
  /*background-color: #044d7f;
    border-color: #204d7f;*/
  font-size: 20px !important;
  letter-spacing: 1px;
  padding: 12px 0 !important;
  border-radius: 5px;
  margin-top: 30px !important;
}

.top-btn {
  text-transform: uppercase;
  color: #ffffff !important;
  background-color: #044d7f !important;
  border-color: #204d7f;
  /* padding-left: 1rem !important; */
  /* padding-right: 1rem !important; */
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 10px 20px !important;
}

.top-btn:hover {
  color: #ffffff;
  background-color: #808080;
  border-color: #808080;
}

.btn-link-custom {
  width: 93% !important;
  float: left;
  cursor: pointer;
}

.oi-caret-bottom,
.oi-caret-top {
  cursor: pointer;
}

.save-btn {
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff !important;
  background-color: #044d7f;
  border-color: #204d7f;
  /* padding-left: 1rem !important; */
  /* padding-right: 1rem !important; */
  font-weight: bold;

  letter-spacing: 1px;
  padding: 10px 5px !important;
  margin-bottom: 0px !important;
  width: 100%;
}

.save-btn:hover {
  color: #808080 !important;
  background-color: #fff;
  border-color: #fff;
}

.com-btn {
  text-transform: uppercase;
  color: #ffffff !important;
  background-color: #044d7f;
  border-color: #204d7f;
  /* padding-left: 1rem !important; */
  /* padding-right: 1rem !important; */
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 10px 5px !important;
  margin-bottom: 0px !important;
  width: 100%;
}

.com-btn:hover {
  color: #fff !important;
  background-color: #808080;
  border-color: #808080;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529 !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  line-height: 27px;
  font-size: 15px;
}

.profile-object {
  line-height: 1;
  text-align: right;
}

.profile-name {
  color: #044d7f;
  font-size: 1rem;
  line-height: 25px;
}

.profile-company,
.profile-subscription {
  color: #7f7f7f;
  font-size: 0.75rem;
  position: relative;
  padding-right: 0rem;
}

.wrapper {
  background: #fcfcfc;
  /* box-shadow: inset 0 .125rem .125rem rgba(0,0,0,.075) !important;*/
}

/** end JC Styles **/
.background-grey {
  background: #f7f7f7;
}

.text-black {
  color: #111;
}

.special-text-small {
  font-size: 0.75em !important;
}

.text-small {
  font-size: 0.75em;
}

.text-big {
  font-size: 1.25em;
}

.hover-dropdown-body {
  background: #fff;
  border: 1px #dcdcdc solid;
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  z-index: 99999999;
}

.p-relative {
  position: relative;
}

.simple-list {
  list-style: outside none;
  margin: 0;
  padding: 0;
}

/* noiuslider css ================================================ */
.noUi-value-large {
  font-size: 0.6em;
}

.range-slider-holder {
  height: 40px;
}

#step-amounts span {
  position: relative;
  display: inline-block;
  font-size: 12px;
  top: -4px;
  text-align: center;
  border: 1px dashed red;
}

#step-amounts span:last-child {
  text-align: right;
  float: right;
}

#step-amounts span:first-child {
  text-align: left;
  float: left;
}

/**/

/*! nouislider - 12.1.0 - 10/25/2018 */
/*.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto;
}

.noUi-target {
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3fb8af;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-handle {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

.noUi-handle:after,
.noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  left: 14px;
  top: 6px;
}

.noUi-handle:after {
  left: 17px;
}

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled] .noUi-handle,
[disabled].noUi-handle,
[disabled].noUi-target {
  cursor: not-allowed;
}

.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  position: absolute;
  background: #ccc;
}

.noUi-marker-sub {
  background: #aaa;
}

.noUi-marker-large {
  background: #aaa;
}

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 40px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 8px;
}

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub,
.noUi-marker-vertical.noUi-marker-large {
  width: 10px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-connect {
  background-color: #1998ca;
}*/

.f08em {
  font-size: 0.8em;
}

.border-2 {
  border-width: 2px !important;
}

/** videoInstructionsModal **/
/*#videoInstructionsModal .modal-body {
    padding: 0;
}
#videoInstructionsModal button.close {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
}*/
.max-height-250 {
  max-height: 250px;
}

.max-height-200 {
  max-height: 200px;
}

.overflow-auto {
  overflow: auto;
}

table.search-only tr.type-download,
table.download-only tr.type-search {
  display: none !important;
}

.margin-0 {
  margin: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-pa {
  color: #044d7f !important;
}

input[type="radio"].special-radio-btn {
  display: none;
}

input[type="radio"].special-radio-btn+label:before {
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  content: " ";
  display: inline-block;
  vertical-align: text-top;
  border: 1px solid #777;
  border-radius: 50%;
}

input[type="radio"].special-radio-btn:checked+label:before {
  background: #044d7f;
}

.searchResults table thead tr th:not(:first-child) {
  text-align: center;
}

.searchResults table tbody tr td:not(:first-child) {
  text-align: center;
}

/*
#searchResultsTotals  tr td{
    text-align: center;
    display: inline-block;

}*/
.roleTitle {
  text-decoration: underline;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.login-bg {
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
  border-radius: 5px;
  margin-top: 0px;
}

.login-bg img {
  margin-left: -15px;
}

.login-bg-reg img {
  margin-left: 0 !important;
}

.navbar {
  padding: 0.5rem 30px;
}

.login-right {
  padding: 0px 40px 0px 20px;
}

.login-right h1,
.register-right h1 {
  font-size: 35px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.form-control {
  /* height: calc(1.5em + 0.75rem + 12px) !important; */
}

.login-link a {
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
}

.register-right {
  padding: 90px 40px 90px 20px;
}

.dark-grey-bg {
  background: #808080;
  padding: 15px 0 !important;
}

.dark-grey-bg h2 {
  font-size: 22px;
  text-align: right;
  color: #fff;
}

.dark-grey-bg h3 {
  font-size: 18px;
}

.input-group-sm>.form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 10px);
}

.input-group-sm>.input-group-append>.btn {
  padding: 0.25rem 0.9rem;
}

.btn-secondary {
  border-color: #808080;
  background-color: #808080;
}

.left-box {
  background: #fff;
  box-shadow: 0px 2px 9px 0px rgba(50, 50, 50, 0.16);
  border-radius: 5px;
  margin-top: 0px;
}

.left-box .card-header {
  background-color: transparent;
  border-bottom: 0;
}

.left-box .card {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.left-box .oi:empty:before {
  font-size: 10px;
  color: #044d7f;
}

.left-box .card button {
  color: #111;
  line-height: 30px;
  font-size: 16px;
  border-radius: 0px;
}

.search-gov {
  width: 100% !important;
  margin: 0 auto;
}

.search-gov .table td {
  border-top: none;
}

.search-gov .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

.left-box .card button:hover {
  text-decoration: none;
  color: #044d7f;
}

.middle-search h3 {
  float: left;
}

.middle-search span {
  float: right;
}

/* New user survey modal */

.usersurvey-roles-wrap {
  padding: 10px 0 !important;
}

.button.loading,
button.loading,
input[type="button"].loading,
input[type="reset"].loading,
input[type="submit"].loading {
  position: relative;
}

.button.loading,
button.loading,
input[type="button"].loading,
input[type="reset"].loading,
input[type="submit"].loading {
  opacity: 0.5;
  pointer-events: none;
}

.button.loading::after,
button.loading::after,
input[type="button"].loading::after,
input[type="reset"].loading::after,
input[type="submit"].loading::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  font-family: FontAwesome !important;
  font-weight: 900;
  line-height: inherit;
  vertical-align: baseline;
  content: "\f110";
  -webkit-animation: fa-spin 0.95s linear infinite;
  animation: fa-spin 0.95s linear infinite;
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  color: #020100;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: none !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

/*my-search*/
.my-search {
  background: #808080;
  padding: 18px 30px;
}

.my-search h1 {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0px;
}

.my-3 label:before {
  vertical-align: middle !important;
}

.dataTables_length select {
  background: #fff;
}

.myclass2 {
  border: 1px solid #ced4da;
  padding: 6px;
  border-radius: 0 px;
}

#resultsTable_wrapper .paging_simple_numbers .paginate_button.current,
#MyBillingTable_wrapper .paging_simple_numbers .paginate_button.current,
#cusReportTable_wrapper .paging_simple_numbers .paginate_button.current {
  background: #044c7e !important;
  border: 1px solid #eee !important;
  color: #fff !important;
  padding: 3px 10px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 3px 10px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background-color: #808080 !important;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #2b2b2b),
      color-stop(100%, #0c0c0c)) !important;
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%) !important;
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%) !important;
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%) !important;
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%) !important;
  background: linear-gradient(to bottom, #808080 0%, #808080 100%) !important;
  box-shadow: inset 0 0 3px #808080 !important;
  border: 1px solid #808080 !important;
  border-radius: 0px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: #808080 !important;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #808080),
      color-stop(100%, #111)) !important;
  background: -webkit-linear-gradient(top, #808080 0%, #808080 100%) !important;
  background: -moz-linear-gradient(top, #808080 0%, #808080 100%) !important;
  background: -ms-linear-gradient(top, #808080 0%, #808080 100%) !important;
  background: -o-linear-gradient(top, #808080 0%, #808080 100%) !important;
  background: linear-gradient(to bottom, #808080 0%, #808080 100%) !important;
  border: 1px solid #808080 !important;
  border-radius: 0px;
}

.search-table thead th {
  vertical-align: middle !important;
  background-position: right center;
  border-top: 0px solid #dee2e6 !important;
}

.search-table {
  width: 100% !important;
  border: 1px solid #eee !important;
  border-radius: 8px !important;
  margin-bottom: 20px !important;
}

/*My Account*/
.account-test {
  font-size: 17px;
  background: #044c7e;
  color: #fff;
  padding: 10px 15px;
  margin: 0px;
}

.add-subscribe {
  padding: 20px 13px !important;
}

.add-subscribe p {
  font-size: 15px;
}

.sub-user {
  font-size: 19px;
}

.sub-btn {
  margin-bottom: 10px;
}

.weekly-check:before {
  border: #034c7d solid 1px !important;
  border-radius: 0px !important;
  top: 0px !important;
}

.weekly-check:after {
  top: 0px;
}

.upgrade-sec {
  margin-top: 20px;
}

.purchase-btn {
  padding: 10px;
}

.MuiTableCell-root {
  padding: 10px !important;
}

.MuiTableHead-root th {
  font-weight: 700;
  /*text-transform: uppercase;*/
  color: #fff !important;
  background-color: #044c7e !important;
  border-color: #044c7e !important;
}

.MuiTableSortLabel-root:hover {
  color: #808080 !important;
}

.MuiIconButton-label {
  color: #044c7e !important;
}

.MuiTableHead-root td {
  text-align: right !important;
}

.profile-subscription::after {
  font-size: 17px;
  border-top: 0.3em solid #044d7f;
  position: absolute;
  top: 6px;
  right: 0px;
}

.profile-company::after {
  font-size: 17px;
  border-top: 0.3em solid #044d7f;
  position: absolute;
  top: 6px;
  right: 0px;
}

.remember-label {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.remember-label .remember-check {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.remember-label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
}

.remember-label:hover .remember-check~.checkmark {
  background-color: #ccc;
}

.remember-label .remember-check:checked~.checkmark {
  background-color: #044d7f;
}

.remember-label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.remember-label .remember-check:checked~.checkmark:after {
  display: block;
}

.remember-label .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.remember-form {
  padding: 0px;
}

.login-right input {
  border-radius: 0px;
}

.login-right button {
  border-radius: 0px;
}

.reset-password input {
  border-radius: 0px;
  margin-bottom: 30px;
}

.reset-password button {
  border-radius: 0px;
}

.reset-password h1 {
  font-size: 35px;
  margin-bottom: 35px;
}

.reset-password a {
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
}

.reset-password .alert-success {
  border-radius: 0px;
}

.agree-label {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.agree-label .agree-check {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.agree-label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #044d7f;
}

.agree-label:hover .agree-check~.checkmark {
  background-color: #ccc;
}

.agree-label .agree-check:checked~.checkmark {
  background-color: #044d7f;
}

.agree-label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.agree-label .agree-check:checked~.checkmark:after {
  display: block;
}

.agree-label .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.agree-form {
  padding: 0px;
}

.header-drop-menu {
  padding: 0px;
}

.header-drop-menu button:hover {
  background-color: #044d7f !important;
}

/* .navbar-text{
    width: 100%;
} */
.navbar-light .navbar-nav .nav-link {
  color: black;
}

.header-drop-menu a:hover {
  background-color: #044d7f !important;
  color: #fff !important;
}

.new-top-btn {
  border-radius: 0px;
}

.search-sidebar .left-box {
  border-radius: 0px;
}

.com-btn {
  border-radius: 0px;
}

.sidebar-label {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
}

.sidebar-label .sidebar-check {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.sidebar-label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #044d7f;
}

.sidebar-label:hover .sidebar-check~.checkmark {
  background-color: #ccc;
  border: 0px solid #044d7f;
}

.sidebar-label .sidebar-check:checked~.checkmark {
  background-color: #044d7f;
}

.sidebar-label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sidebar-label .sidebar-check:checked~.checkmark:after {
  display: block;
}

.sidebar-label .checkmark:after {
  left: 4px;
  top: 2px;
  width: 6px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sidebar-form {
  padding: 0px 0px 0px 5px;
  height: auto;
}

.subsp-credit {
  font-weight: 300;
}

.weekly-check:checked:before {
  background-color: #044c7e;
}

#editSubUserModal input {
  border-radius: 0px;
}

.manage-sub-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-sub-btn button {
  padding: 12px 40px !important;
  margin-right: 15px;
}

.manage-sub-btn a {
  padding: 12px 40px !important;
  border-radius: 0px;
  margin-left: 15px;
}

#addSubUserModal input {
  border-radius: 0px;
}

.subpt-user-table {
  margin-bottom: 20px !important;
}

#subUserView_info,
#subUserView_paginate {
  margin-bottom: 20px;
}

#subscriptionHistoryTable_info,
#subscriptionHistoryTable_paginate {
  margin-bottom: 20px;
}

#transactionHistoryTable_info,
#transactionHistoryTable_paginate {
  margin-bottom: 20px;
}

#subtransactionHistoryTable_info,
#subtransactionHistoryTable_paginate {
  margin-bottom: 20px;
}

#subUserView_paginate .paginate_button.current {
  background: #044d7f !important;
  color: #fff !important;
  border: 0px solid #044d7f !important;
  border-radius: 0px !important;
}

#subscriptionHistoryTable_paginate .paginate_button.current {
  background: #044d7f !important;
  color: #fff !important;
  border: 0px solid #044d7f !important;
  border-radius: 0px !important;
}

#transactionHistoryTable_paginate .paginate_button.current {
  background: #044d7f !important;
  color: #fff !important;
  border: 0px solid #044d7f !important;
  border-radius: 0px !important;
}

#subtransactionHistoryTable_paginate .paginate_button.current {
  background: #044d7f !important;
  color: #fff !important;
  border: 0px solid #044d7f !important;
  border-radius: 0px !important;
}

#changePasswordModal input {
  border-radius: 0px;
}

#changePasswordModal input {
  margin-bottom: 20px;
}

.acc-subrcp {
  padding-bottom: 47px !important;
}

.switch1 {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  padding-right: 93px;
}

.switch1 input {
  display: none;
}

.switch1 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
  background-color: #808080;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 77px;
  height: 32px;
}

.switch1 .slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 22px;
  left: 5px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch1 input:checked+.slider {
  background-color: #044c7e;
}

.switch1 input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch1 input:checked+.slider:before {
  -webkit-transform: translateX(48px);
  -ms-transform: translateX(48px);
  transform: translateX(48px);
}

.credits-purchase1 {
  float: left;
}

.credits-purchase2 {
  float: right;
}

.credits-purchase2 input {
  border-radius: 0px !important;
}

.record-table {
  margin-top: 20px;
}

.record-table th {
  background: #808080;
  padding: 5px 10px;
  border-right: 1px solid #fff;
}

.record-table td {
  background: #f2f2f2;
  padding: 5px 10px;
  border-right: 1px solid #fff;
}

.record-table th label {
  font-weight: 400;
  color: #fff;
  margin: 0px;
}

#filter-section .refine-filter label {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#filter-section .refine-filter {
  padding: 5px;
}

#filter-section .refine-filter label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

#filter-section .refine-filter label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #044d7f;
}

#filter-section .refine-filter input:indeterminate~.checkmark {
  background: #044c7e;
}

#filter-section .refine-filter label:hover input~.checkmark {
  background-color: #ccc;
}

#filter-section .refine-filter label input:checked~.checkmark {
  background-color: #044d7f;
}

#filter-section .refine-filter label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

#filter-section .refine-filter label input:checked~.checkmark:after {
  display: block;
}

#filter-section .refine-filter label .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.state-locate .checkmark:before {
  display: none;
}

.state-label {
  display: inline-block !important;
}

.mysearch-label {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
}

.mysearch-label .mysearch-check {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.mysearch-label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #044d7f;
}

.mysearch-label:hover .mysearch-check~.checkmark {
  background-color: #ccc;
  border: 1px solid #044d7f;
}

.mysearch-label .mysearch-check:checked~.checkmark {
  background-color: #044d7f;
}

.mysearch-label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.mysearch-label .mysearch-check:checked~.checkmark:after {
  display: block;
}

.mysearch-label .checkmark:after {
  left: 4px;
  top: 2px;
  width: 6px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mysearch-form {
  padding-left: 0px !important;
  padding-top: 10px;
}

.login-section {
  padding: 50px 0px !important;
}

.site-footer {
  /* margin: 0px !important; */
}

.search-sidebar {
  margin-bottom: 40px;
}

.search-list-table {
  margin-bottom: 40px;
}

#MyBillingTable_wrapper {
  margin-bottom: 40px;
}

#MyBillingTable_length {
  text-align: left;
}

#MyBillingTable_filter {
  text-align: left;
}

.download-trans {
  padding-bottom: 40px;
}

.credit-result {
  padding-bottom: 40px;
}

.online-btn {
  margin-bottom: 30px;
}

.pay-offline-btn {
  margin-bottom: 40px;
}

.sub-reg-block {
  padding-bottom: 40px;
}

/*------ ADDED CSS ---------*/
.switch1 .on {
  display: none;
}

.switch1 .on,
.switch1 .off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

.switch1 input:checked+.slider .on {
  display: block;
}

.switch1 input:checked+.slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.switch1 .slider.round {
  border-radius: 0px;
}

.switch1 .slider.round:before {
  border-radius: 0px;
}

.custom-checkbox {
  padding-left: 0px !important;
}

/* Register page */
.register-block .login-right {
  padding: 0px 40px 75px 20px;
}

#subUserView_filter input {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

#subUserView_filter input::placeholder {
  font-size: 14px;
}

#transactionHistoryTable {
  margin-bottom: 40px !important;
}

.acc-subp-left {
  padding-bottom: 40px !important;
}

.curr-sub {
  margin-bottom: 5px;
}

.credit-avbl {
  margin-bottom: 5px;
}

.tanrs-table td span {
  padding: 0px 15px;
  float: right;
}

.tanrs-table td .credit-left strong {
  font-weight: normal !important;
}

.tanrs-table td .credit-left.credit-main strong {
  font-weight: 600 !important;
}

.back_btn {
  color: #212529 !important;
  padding: 10px 15px;
  border: 2px solid #044d7f;
  margin: 25px 0px;
  display: inline-block;
  text-decoration: none !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.back_btn span {
  padding-right: 10px;
}

.back_btn span i {
  font-size: 14px;
  font-weight: 600;
}

.back_btn:hover {
  background: #808080;
  border: 2px solid #808080;
  color: #fff !important;
}

.power-max .et_pb_pricing {
  padding: 27px !important;
}

.power-max .et_pb_pricing li {
  padding: 0 0 1.6em 0px !important;
}

/* @media (max-width: @screen-xs-min) {
  .modal-xs { width: @modal-sm; }
} */
/*responsive class*/
.login-block {
  /* min-height: 496px; */
}

@media only screen and (max-width: 1024px) and (min-width: 992px) {
  .login-section {
    padding: 30px 10px !important;
  }

  .login-block .login-right {
    padding: 5px 20px 0px 20px;
  }

  .login-right input {
    margin-bottom: 25px;
  }

  .login-block .login-right h1 {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .login-link .login-btn {
    flex: 0 0 30%;
  }

  .login-link .reg-btn {
    flex: 0 0 30%;
  }

  .login-link .frg-btn {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .register-block .login-right h1 {
    font-size: 30px;
    margin-bottom: 25px;
  }

  .register-block .login-right button {
    font-size: 14px;
    padding: 9px 5px !important;
  }

  .register-block .login-right input {
    border-radius: 0px;
    margin-bottom: 25px;
  }

  .register-block .login-right {
    padding: 30px 40px 30px 20px;
  }

  .forgotpw-block .login-right {
    padding: 80px 40px 80px 20px;
  }

  .search-sidebar h2 {
    font-size: 24px;
  }

  .search-gov h2 {
    font-size: 24px;
  }

  .search-sidebar .upgrade-plan-button {
    font-size: 14px;
  }

  .search-sidebar .upgrade-plan-button span {
    font-size: 18px;
  }

  .result-table th {
    padding: 12px 5px;
  }

  .head-of-label {
    font-size: 12px;
  }

  .switch1 {
    width: 400px;
  }

  .acc-subp-left {
    padding-bottom: 95px !important;
  }

  .not-sure {
    font-size: 18px !important;
  }

  .upgrade-sec h4 {
    font-size: 21px;
  }

  .record-title {
    font-size: 27px;
  }

  .purchaseform h3 {
    font-size: 21px;
  }

  .qust-upgrade {
    font-size: 21px;
  }

  .box-profile h3 {
    font-size: 21px;
  }
}

@media only screen and (max-width: 991px) {
  .login-block .login-right {
    padding: 5px 40px 0px 20px;
  }

  #loader .display-4 {
    font-size: 24px;
  }

  .login-section {
    padding: 50px 0px !important;
  }

  .login-block .login-right h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .login-block .login-right input {
    margin-bottom: 15px;
  }

  .login-btn button {
    padding: 8px 0px !important;
    font-size: 12px;
  }

  .login-block .login-right .form-control {
    height: calc(1.5em + 0.75rem + 0px);
  }

  .remember-label {
    margin-bottom: 0px;
  }

  .login-link .login-btn {
    flex: 0 0 100%;
  }

  .login-link .reg-btn {
    flex: 0 0 100%;
    padding: 20px;
  }

  .login-link .frg-btn {
    /*flex: 0 0 100%;*/
    max-width: 100%;
  }

  .align-items-center {
    align-items: inherit;
  }

  .login-link a {
    font-size: 12px;
  }

  .register-block .login-right h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .register-block .login-right button {
    font-size: 14px;
    padding: 9px 5px !important;
  }

  .register-block .login-right input {
    border-radius: 0px;
    margin-bottom: 25px;
  }

  .register-block .login-right {
    padding: 30px 40px 30px 20px;
  }

  .reg-leftblock {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .reg-leftblock img {
    width: 100%;
    margin: 0px;
    padding-top: 15px;
    height: 350px;
  }

  .reg-rightblock {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .login-bg-reg img {
    margin-left: 0 !important;
    /*width: 66% !important;*/
  }

  .register-block .login-link a {
    font-size: 14px;
  }

  .forgotpw-block .login-right h1 {
    font-size: 27px;
    margin-bottom: 20px;
  }

  .forgotpw-block .login-link button {
    font-size: 14px;
    padding: 6px 0 !important;
  }

  .forgotpw-block .forgot-col {
    max-width: 33%;
  }

  .return-lg {
    max-width: 60%;
    flex: 0 0 60%;
  }

  .forgotpw-block .login-right input {
    margin-bottom: 20px;
  }

  .forgotpw-block .login-right h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .forgotpw-block .login-right {
    padding: 40px 40px 40px 20px;
  }

  .search-sidebar h2 {
    font-size: 24px;
  }

  .search-gov h2 {
    font-size: 24px;
  }

  .search-sidebar .upgrade-plan-button {
    font-size: 14px;
  }

  .search-sidebar .upgrade-plan-button span {
    font-size: 18px;
  }

  .search-headblock {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .search-headblock h2 {
    font-size: 19px !important;
  }

  .search-govblock {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .search-gov {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .result-table th {
    padding: 12px 5px;
  }

  .num-gov {
    text-align: right;
    padding-bottom: 20px;
  }

  .all-searches {
    margin-right: 20px !important;
  }

  .all-searches label {
    font-size: 15px;
  }

  .all-searches label:before {
    width: 12px !important;
    height: 12px !important;
    margin-top: -2px;
  }

  #resultsTable_wrapper .paging_simple_numbers .paginate_button.current,
  #MyBillingTable_wrapper .paging_simple_numbers .paginate_button.current,
  #cusReportTable_wrapper .paging_simple_numbers .paginate_button.current {
    padding: 0px 9px !important;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 1px 10px !important;
  }

  #subscriptionHistoryTable {
    table-layout: fixed;
    display: block !important;
  }

  .not-sure {
    font-size: 16px !important;
  }

  .upgrade-sec h4 {
    font-size: 18px;
  }

  .record-title {
    font-size: 24px;
  }

  .purchaseform h3 {
    font-size: 18px;
  }

  .qust-upgrade {
    font-size: 21px;
  }

  .box-profile h3 {
    font-size: 18px;
  }

  .pricing .title {
    font-size: 30px;
  }

  .purchaseform .card-header {
    padding: 10px 5px;
  }

  .credits-purchase1 {
    max-width: 64%;
  }

  .credits-purchase2 {
    float: right;
    max-width: 34%;
  }

  .box-profile p {
    font-size: 13px;
  }

  .record-plan {
    min-height: 90px;
  }

  .current-plan {
    padding: 7px 0;
    cursor: not-allowed;
  }

  .doenload-btn {
    font-size: 13px;
  }

  .download-records {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
  }

  /*stripe-card-css*/
  /*.CardField-input-wrapper .CardField-number, .CardField-input-wrapper .CardField-expiry, .CardField-input-wrapper .CardField-cvc, .CardField-input-wrapper .CardField-postalCode{
        transform: translateX(0px) !important;
        display: block !important;
        width: 100% !important;
    }
    .CardField-child{
        transform: translateX(0px) !important;
        display: block !important;
        width: 100% !important;
    }
    .StripeElement iframe{
        height: 200px;
    }*/
  /*stripe-card-css*/
  .sub-reg-block .account-test {
    font-size: 16px;
  }

  .sub-reg-block #login-or-register input {
    font-size: 14px;
    padding: 7px;
  }
}

@media (min-width: 768px) {

  .search-table,
  .subpt-user-table,
  .power-entry-table {
    display: inline-table !important;
  }

  .profile-name,
  .profile-company {
    padding-right: 1rem;
  }

  .nav-item.dropdown {
    min-width: 350px;
  }
}

#footer {
  border-top: 1px solid #e9ecef;
}

.copyright {
  font-size: 14px;
  padding: 20px 0;
  text-align: center;
  margin: 0;
}

@media (max-width: 768px) {
  .myclass2 {
    padding: 5px;
  }

  .myclass2::placeholder {
    font-size: 15px;
  }

  .my-search {
    padding: 18px 20px;
  }

  .my-search h1 {
    font-size: 19px;
  }

  .search-table thead th {
    font-size: 14px;
    text-align: left !important;
    padding: 10px 9px !important;
  }

  .search-table tbody td {
    font-size: 14px;
    text-align: left !important;
  }

  .action-btn {
    margin-bottom: 10px;
  }

  .subpt-user-table {
    font-size: 14px;
  }

  .login-section {
    padding: 15px 0px !important;
  }

  .StripeElement input::placeholder {
    font-size: 14px;
  }

  .StripeElement input {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .save-new-search {
    margin-top: 10px;
  }

  .login-right h1,
  .register-right h1 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .logo {
    max-width: 120px;
  }

  .refbtn {
    width: 100px;
    height: 63px;
  }

  .navbar-collapse {
    margin: 0 -1rem;
  }

  #navbarDropdown::after {
    display: none !important;
  }

  .dropdown-menu {
    /* Override mobile dropdown as its unnecessary;*/
    background: #fefefe !important;
    border: none;
    display: block !important;
    text-align: center;
  }

  .profile-object {
    background: #fcfcfc !important;
    border-bottom: 1px solid #e9ecef;
    padding: 1rem 0 1.5rem 0;
    text-align: center;
  }

  .nav-link.btn {
    border-radius: 0;
  }

  .copyright-links {
    display: block;
  }

  .login-section {
    padding-top: 0rem !important;
  }

  .login-bg img {
    margin-left: 0;
    height: 200px;
    width: 100%;
  }

  .login-bg-reg img {
    margin-left: 0 !important;
    height: 200px;
    width: 100%;
  }

  .login-right {
    padding: 40px 20px 50px 20px;
  }

  .login-bg {
    margin-top: 40px;
  }

  .dark-grey-bg {
    padding: 20px 0 !important;
  }

  .dark-grey-bg h2 {
    text-align: left;
    margin-bottom: 10px !important;
  }

  .dark-grey-bg .save-btn {
    /* margin-top: 10px;*/
  }

  .search-gov {
    width: 90% !important;
    margin: 0 auto;
  }

  .search-gov .com-btn {
    /* margin-top: 15px*/
  }

  .acc-subp-left {
    padding-bottom: 20px !important;
  }

  .action-btn {
    margin-bottom: 0px;
  }
}

@media (max-width: 767px) {
  all-searches {
    margin-right: 15px !important;
    font-size: 15px;
  }

  .my-search {
    padding: 12px 20px;
  }

  #resultsTable_length {
    font-size: 14px;
  }

  #resultsTable_length select {
    padding: 0px 5px;
    margin: 0px 5px;
    font-size: 13px;
  }

  #resultsTable_filter label {
    font-size: 14px;
  }

  .myclass2::placeholder {
    font-size: 12px;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button {
    font-size: 14px;
  }

  #resultsTable_info {
    font-size: 14px;
  }

  .search-table,
  .subpt-user-table,
  .power-entry-table {
    display: block !important;
  }

  .add-subscribe p {
    margin-bottom: 10px;
  }

  .add-subscribe .col {
    flex-basis: inherit;
  }

  .acc-subp {
    flex-basis: inherit;
  }

  .credit-avbl {
    margin-top: 10px;
  }

  .sub-user {
    font-size: 16px;
  }

  .sub-btn {
    padding: 7px;
  }

  .login-bg {
    padding: 20px 0px;
  }

  .login-btn button {
    padding: 10px 0px !important;
    font-size: 14px;
  }

  .login-link a {
    font-size: 13px;
  }

  .login-block .login-right {
    padding: 28px 40px 0px 20px;
  }

  .login-block .login-right h1 {
    font-size: 27px;
    margin-bottom: 25px;
    padding: 28px 20px 0px 20px;
  }

  .forgotpw-block .login-link button {
    font-size: 15px;
    padding: 11px 0 !important;
  }

  .search-headblock h2 {
    font-size: 24px !important;
    text-align: right !important;
  }

  .search-headblock h2 {
    font-size: 24px !important;
    text-align: right !important;
  }

  .search-header .header-right-dropdown-menu {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .result-table th {
    padding: 12px 2px;
    font-size: 14px;
  }

  .result-table td {
    font-size: 14px;
  }

  .search-headblock {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .credits-purchase1 label {
    font-size: 19px;
  }

  .qust-upgrade {
    font-size: 18px;
  }

  .sub-reg-block {
    width: 100%;
    max-width: 100%;
  }

  .tanrs-table td {
    display: block;
    width: 99.9%;
    clear: both;
  }

  .tanrs-table td span {
    width: 25%;
  }

  .tanrs-table td .credit-left strong {
    float: left;
    width: 75%;
    font-weight: normal !important;
  }

  .tanrs-table td {
    border-top: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .reg-center h1 {
    color: #044d7f;
    font-size: 24px;
  }

  .reg-img {
    padding-top: 15px;
  }

  .left-reg li {
    font-size: 16px !important;
    line-height: 25px;
  }

  .login-section {
    padding: 20px 15px !important;
  }

  .login-bg {
    margin: 0px !important;
    padding: 0px !important;
  }

  .login-block .login-bg {
    padding-top: 15px !important;
  }

  .forgotpw-block .login-bg {
    padding-top: 15px !important;
  }

  .search-headblock h2 {
    font-size: 21px !important;
  }

  .search-sidebar h2 {
    font-size: 21px;
  }

  .result-table th {
    padding: 12px 1px;
  }

  .result-table th select {
    height: auto;
  }

  .search-dropdown-header h3 {
    font-size: 15px;
  }

  .middle-search span {
    font-size: 14px;
  }

  .my-search ul.breadcrumb li {
    font-size: 15px;
  }

  .billing-head {
    font-size: 24px;
  }

  .record-plan {
    min-height: 80px;
  }

  .credits-purchase2 input {
    padding: 5px;
    height: auto;
  }

  .credits-purchase1 label {
    font-size: 16px;
  }

  .payoption-table td {
    font-size: 14px;
  }

  .payoption-table th {
    font-size: 14px;
  }

  .pay-col h3 {
    font-size: 21px;
  }

  .verify-btn {
    padding: 7px !important;
    font-size: 12px;
  }

  .sub-reg-block .account-test {
    font-size: 14px;
  }

  .download-records h3 {
    font-size: 24px;
  }

  .reg-leftblock {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .reg-leftblock img {
    width: 100%;
    margin: 0px;
    padding-top: 15px;
    height: 150px;
  }

  .login-bg-reg img {
    margin-left: 0 !important;
    height: 200px;
    width: 100% !important;
  }
}

@media (max-width: 425px) {
  .my-search h1 {
    font-size: 16px;
  }

  .my-3 label:before {
    vertical-align: middle !important;
    width: 10px !important;
    height: 10px !important;
  }

  .all-searches {
    margin-right: 8px !important;
    font-size: 14px;
  }

  .rename-saved label {
    font-size: 14px;
  }

  .plan-btn {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .refbtn {
    width: 138px;
    height: 63px;
    padding-right: 5px;
  }

  .logo {
    max-width: 100px;
  }

  .login-block .login-right h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .login-link .login-btn {
    flex: 0 0 100%;
  }

  .login-link .reg-btn {
    flex: 0 0 100%;
    padding: 20px;
  }

  .login-link .frg-btn {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .register-block .login-right h1 {
    font-size: 17px;
  }

  .forgotpw-block .forgot-col {
    max-width: 40%;
  }

  .billing-head {
    font-size: 21px;
  }

  .credits-purchase1 label {
    font-size: 15px;
  }

  .record-plan {
    min-height: 80px;
  }

  .my-search ul.breadcrumb li {
    font-size: 13px;
  }

  .download-records h3 {
    font-size: 21px;
  }

  .doenload-btn {
    font-size: 12px;
    padding: 5px !important;
  }

  .upgrade-modal {
    table-layout: fixed;
    overflow-wrap: break-word;
    font-size: 14px;
  }

  .upgrade-modal input {
    font-size: 14px;
  }

  .upgrade-modal textarea {
    font-size: 14px;
  }

  .invoice-table {
    table-layout: fixed;
    overflow-wrap: break-word;
    font-size: 14px;
  }

  .pay-off-modal {
    display: block !important;
  }

  .pay-off-modal button {
    margin-bottom: 12px;
  }

  .sub-reg-block .account-test {
    font-size: 13px;
    padding: 10px 5px;
  }
}

@media only screen and (max-width: 320px) {
  .navbar {
    padding: 0.5rem 12px;
  }

  .refbtn {
    width: 128px;
    height: 55px;
    padding-right: 5px;
  }

  .logo {
    max-width: 95px;
  }

  .reg-rightblock form iframe {
    width: 260px;
  }

  .register-block .login-right button {
    font-size: 13px;
  }

  .register-block .login-link a {
    font-size: 13px;
  }

  .forgotpw-block .forgot-col {
    max-width: 100%;
  }

  .return-lg {
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
    padding: 20px 0px 0px;
  }

  .search-headblock h2 {
    font-size: 19px !important;
    text-align: right !important;
  }

  .search-headblock {
    flex: 0 0 46%;
    max-width: 47%;
  }

  .search-header .header-right-dropdown-menu {
    flex: 0 0 54%;
    max-width: 54%;
  }

  .search-dropdown-header h3 {
    font-size: 15px;
  }

  .middle-search span {
    font-size: 14px;
  }

  .my-search ul.breadcrumb li {
    font-size: 15px;
  }

  .credits-purchase1 label {
    font-size: 13px;
  }

  .upgrade-sec h4 {
    font-size: 16px;
  }

  .not-sure {
    font-size: 14px !important;
  }

  .download-records {
    padding: 2px;
  }

  .doenload-btn {
    font-size: 11px;
  }

  .verify-btn {
    font-size: 10px;
  }

  .manage-sub-btn button {
    margin-right: 0px;
  }
}

/*@media (max-width: 375px){
    .search-table{
        width: 80% !important;
        overflow: scroll;
    }
}*/

ul.breadcrumb li {
  color: #fcfcfc;
  text-decoration: none;
}

#payOfflineModal button:focus,
#stripe-modal button:focus,
#videoInstructionsModal button:focus {
  outline: none !important;
}

.invoice_modal_header .close {
  padding: 0 !important;
  margin: 0 !important;
}

.invoice_modal_title {
  margin: 0 auto;
  font-size: 17px !important;
  font-weight: 500;
}

.invoice-table td,
.invoice-table th {
  padding: 0.4rem !important;
}

.invoice-items td,
.invoice-items th {
  padding: 0.4rem !important;
}

.invoice-footer .btn-pa {
  padding: 10px 10px !important;
}

.invoice-footer {
  padding: 0 !important;
  border-top: none;
  border-bottom-right-radius: none;
  border-bottom-left-radius: none;
}

#searchzipresult {
  padding: 0;
  list-style-type: none;
  position: absolute;
  z-index: 9999;
  width: 80%;
  display: none;
  max-height: 120px;
  overflow: hidden;
  background-color: #eeeeee;
  overflow-y: scroll;
}

#searchzipresult>li {
  text-align: left;
  padding: 5px;
  width: 100%;
  cursor: pointer;
}

#searchzipresult>li:hover {
  background-color: #f9f9f9;
}

.phone-input,
.phone-enter-arrow {
  height: 60px;
  font-weight: 700;
}

.phone-input {
  /* padding-right: 120px !important;*/
}

.intl-tel-input,
.phone-input {
  width: 100%;
}

.phone-input.invalid {
  border-color: red;
}

.phone-input.valid {
  border-color: #0f310b;
}

.phone-container {
  position: relative;
  display: inline-block;
}

.phone-enter-arrow {
  background: none;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
}

.phone-enter-arrow:hover {
  background: none;
}

.intl-tel-input .country-list {
  font-size: 15px;
}

.intl-tel-input .selected-flag .iti-arrow {
  border-top-color: #0033ff;
}

.phone-error,
.phone-success {
  font-size: 16px;
  text-align: center;
}

.phone-success {
  color: #0033ff;
}
