.Component-horizontalScrollContainer-12 {
  margin-top: -3em;
}

.titlebar {
  background: #818081;
  padding: 8px 0;
}

.change-row {
  margin: 0px !important;
}

.edit-gov-button {
  background: #044d7f;
  border-color: #044d7f;
  font-size: 15px;
  padding: 8px 15px;
  font-weight: 600;
  border-radius: .25rem !important;
  color: #ffffff;
  border: 0px !important;
}

.edit-gov-button:hover {
  background: #818081;
  border-color: #818081;
  color: #ffffff;
}

.edit-gov-text {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  padding-right: 15px;
  font-size: 15px;
  line-height: 15px;
}

.MuiToolbar-root {
  background: white !important;
  color: #044c7e !important;
}

.MuiSvgIcon-root {
  background: white !important;
}

.PrivateSwitchBase-input-76 {
  background: white !important;
}

.blurEffect {
  filter: blur(1px);
  width: 100%;
  height: 100%;
  background-color: #ccc;
}

.titlebar h1 {
  font-size: 25px;
  text-align: center;
  color: #fff;
  margin: 0 0;
  padding: 0;
  font-weight: 600;
}

.backColor {
  background-color: #044d7f !important;
  color: white;
}

.backColor span {
  color: white;
}

.grade {
  color: #F00;
}

/* .table-responsive {
  overflow-x: hidden !important;
} */
.dms-login-table td{
  text-align: left !important;
}

.dms-login-table td li{
  list-style: decimal;
  padding-top: 0.5em;
}

.errborder {
  border: 1px solid red !important;
  display: block;
  padding: 5px;
}


.yellowborder {
  border: 2px solid yellow !important;
  display: block;
  padding: 5px;
}

.errInput {
  border: 1px solid red !important;
}

.invalid {
  border: 1px solid red;
}

.long_verified_mark {
  background-color: yellow;
}

.red {
  color: red;
  text-transform: uppercase;
}

.green {
  color: green;
  text-transform: uppercase;
}

.marked {
  background-color: green;
  color: #fff;
}

.marked a {
  color: white !important;
}

.unconfirmedEdit {
  background-color: #FF9;
  color: #fff;
}

.customButtonA {
  background: #2db67e;
  color: #fff !important;
  padding: 6px 15px;
  float: right;
  margin-bottom: 10px;
  text-decoration: none;
  border-radius: 4px;
}

.customButtonA:hover {
  background: #818081;
  color: #fff;
  text-decoration: none;
}

.dmstable li,
.dmstable td {
  font-size: 11px;
  line-height: 15px;
  word-wrap: break-word;
  /* margin-bottom: 5px; */
}

.dmstable p {
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 5px;
}

.dmstable .red {
  font-size: 12px;
  color: red;
}

.dmstable li::marker {
  display: inline;
}

.dmstable th {
  border-bottom: none !important;
  background: #044d7f !important;
  color: #fff;
  text-align: left;
  padding: 0.55rem .75rem;
  font-size: 12px;
  font-weight: normal;
}

.dmstable .table {
  background: #ffffff !important;
  margin-bottom: 0;
}

.white-bg {
  background: #fff;
  box-shadow: 0px 10px 15px 0px rgba(50, 50, 50, 0.16);
  margin-top: 25px;
  padding: 15px;
  margin-bottom: 25px;
  border-radius: 10px;
}

.dms-login {
  margin-top: 5px;
}

.dms-login h1 {
  font-size: 30px;
  text-align: center;
  color: #212529;
  margin: 0 0 15px;
  padding: 0;
  font-weight: bold;
}

.dms-login form {
  width: 50%;
  margin: 0 auto;
  display: table;
  background: #f3f3f4;
  padding: 30px;
  border-radius: 10px;
}
.dms-login .form-group {
    margin-bottom: 0.5em;
}
.dms-login .btn {
  background: #103f83;
  border-color: #103f83;
  font-size: 17px;
  margin: 0 auto;
  display: table;
  padding: 10px 25px;
  font-weight: 600;
  letter-spacing: 1px;
}

.dms-login .btn:hover {
  background: #818081;
  border-color: #818081;
}

.dms-login label {
  font-size: 16px;
  font-weight: 600;
}

.pinavbar li {
  float: left;
  list-style: none;
  margin-left: 5px;
}

.pinavbar li a {
  font-weight: normal;
  font-size: 15px;
  padding: 8px !important;
  background-color: #f3f3f4;
}

.pinavbar li a:hover {
  background-color: #044d7f !important;
  color: #ffffff !important;
}


.pinavbar li span {
  font-weight: normal;
  font-size: 15px;
  padding: 8px !important;
  background-color: #f3f3f4;
}

.pinavbar li span:hover {
  background-color: #044d7f !important;
  color: #ffffff !important;
}

.pi-subtitle {
  margin-top: 15px;
}

.pi-subtitle h2 {
  font-size: 19px;
  font-weight: 600;
  color: #000000;
  padding: 10px 0 0;
  margin: 0;
}

.pi-btn {
  background: #2db67e;
  color: #fff;
  padding: 5px 15px;
  float: right;
  margin-bottom: 10px;
}

.pi-btn:hover {
  background: #818081;
  color: #fff;
}

.voicetitle h2 {
  font-size: 19px;
  font-weight: 600;
  color: #000000;
  padding: 10px 0 10px;
  margin: 0;
}

.govt-id {
  background: #f3f3f4;
  padding: 10px;
  border-radius: 10px;
}

.govt-id h3 {
  font-size: 17px;
  font-weight: 600;
  color: #000000;
  padding: 5px 0;
  margin: 0;
  text-align: center;
}

.govt-id p {
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
  text-align: center;
  background: #818081;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  line-height: 40px;
  color: #fff;
  margin: 0 auto;
}

.govt-id label {
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  padding-right: 15px;
}

.govt-id a {
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 5px;
  display: block;
  width: 100%;
  text-align: center;
  color: #007bff !important;
  text-decoration: underline;
}

.govt-id .input-group {
  width: 75%;
  margin: 0 auto 10px auto;
}

.govt-id .input-group .form-control {
  height: calc(1em + .75rem + 12px) !important;
}

.govt-id .input-group button {
  background: #044d7f;
  border-color: #044d7f;
  font-size: 15px;
  margin: 0 auto;
  display: table;
  padding: 8px 25px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.taskform {
  padding: 20px 50px;
  background: #044d7f;
  margin: 15px 0;
  border-radius: 10px;
}

.taskform h2 {
  font-size: 19px;
  font-weight: 600;
  color: #ffffff;
  padding: 10px 0 10px;
  margin: 0;
  text-align: center;
}

.govt-id .input-group button:hover {
  background: #818081;
  border-color: #818081;
}

.taskform label {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
}

.taskform p {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.taskform .form-control {
  border: transparent !important;
}

.create-task-btn {
  background: #818081;
  border-color: #818081;
  font-size: 15px;
  margin: 20px auto 0 auto;
  display: table;
  padding: 10px 25px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #ffffff;
  border: 0px;
  border-radius: .25rem;
}

.taskform a {
  font-size: 16px;
  color: #ffffff !important;
  text-decoration: underline;
}

.dmstable .navigation {
  margin: 0 auto !important;
}

.blue-btn {
  background: #044d7f;
  border-color: #044d7f;
  font-size: 15px;
  margin: 0 auto;
  display: table;
  padding: 8px 25px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: .25rem !important;
  color: #ffffff;
  border: 0px !important;
}

.blue-btn:hover {
  background: #818081;
  border-color: #818081;
  color: #ffffff;
}

.pipopup .modal-header {
  background: #044d7f;
}

.pipopup .modal-header h5 {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.pipopup .modal-body h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.pipopup .form-control {
  font-size: 13px;
}

.modal-footer button {
  font-size: 14px;
  font-weight: 600;
}

.pipopup label {
  font-size: 15px;
}

.pipopup .close {
  color: #fff;
  opacity: 1;
}

.pipopup .btn-primary {
  color: #fff;
  background-color: #044d7f;
  border-color: #044d7f;
}

.pipopup .btn-primary:hover {
  background: #818081;
  border-color: #818081;
}

.pipopup .form-control {
  height: auto !important;
}

.pipopup .form-group {
  margin-bottom: 10px;
}

.daterange {
  margin: 0 auto 15px auto;
  display: table;
  background: #f3f3f4;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
}

.daterange h1 {
  font-size: 19px;
  font-weight: 600;
  color: #000000;
  padding: 10px 0 0;
  margin: 0;
}

.daterange .form-control {
  height: auto !important;
}

.help {
  background: #f3f3f4;
  padding: 15px;
}

.help h5 {
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 5px;
  font-weight: 600;
}

.help h3 {
  font-size: 17px;
  line-height: 15px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #044d7f;
}

.help p {
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 0px;
}

.dmstable h2 {
  font-size: 19px;
  font-weight: 600;
  color: #000000;
  padding: 15px 0 10px;
  margin: 0;
  text-align: center;
}

.dmstable .table thead th {
  vertical-align: middle;
  text-align: center;
}

.edit-mode {
  width: 50%;
  margin: auto;
}

.edit-mode td:first-child {
  text-align: right !important;
  width: 50%;
  font-weight: bold;
}

.edit-mode td:last-child {
  text-align: left !important;
  margin-left: 1em;
  width: 50%;
  font-weight: bold;
}

.edit-modenav {
  margin-top: 15px;
}

.edit-modenav li a {
  font-weight: normal;
  font-size: 14px;
  padding: 8px 15px !important;
  background-color: #f3f3f4;
  display: block;
  border-radius: .25rem;
  color: #000000;
}

.edit-modenav li {
  float: left;
  list-style: none;
  margin-right: 10px;
}

.edit-modenav ul {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.edit-modenav li a:hover {
  background-color: #044d7f !important;
  color: #ffffff !important;
  text-decoration: none;
}

.edit-modenav h2 {
  color: #044d7f;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.edit-modenav p {
  color: #000000;
  font-size: 15px;
  text-align: center;
}

.edit-table {
  padding: 0px !important;
}

.edit-table th {
  border-bottom: none !important;
  background: #c33339 !important;
  color: #fff;
  text-align: left;
}

.editbtn {
  background: #2db67e;
  font-size: 14px;
  font-weight: 600;
  display: block;
  overflow: hidden;
  border-radius: 3px;
  padding: 8px 10px;
  color: #ffffff !important;
  text-align: center;
  margin-bottom: 5px;
}

.editbtn:hover {
  background: #818081;
  color: #fff;
  text-decoration: none;
}

.edit-table input,
.edit-table select {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  padding: 3px;
  width: 100%;
  border-radius: 3px;
}

.edit-table td {
  padding: 6px 3px;
  border: none;
}

.dmstable .table td,
.table th {
  vertical-align: middle;
  padding: 3px 2px !important;
  text-align: center;
}

.edit-mode .table td {
  padding: 5px !important
}

.edit-table .btn-primary {
  background: #044d7f;
  border-color: #044d7f;
  font-size: 13px;
  padding: 8px 15px;
  font-weight: 600;
  border-radius: .25rem !important;
  color: #ffffff;
  border: 0px !important;
}

.edit-table .btn-primary:hover {
  background: #818081;
  border-color: #818081;
  color: #ffffff;
}

.edit-table .btn-secondary {
  background: #818081;
  border-color: #818081;
  font-size: 13px;
  padding: 8px 15px;
  font-weight: 600;
  border-radius: .25rem !important;
  color: #ffffff;
  border: 0px !important;
}


.viewbtn {
  background: #818081;
  border-color: #818081;
  font-size: 13px;
  padding: 8px 15px;
  font-weight: 600;
  border-radius: .25rem !important;
  color: #ffffff;
  border: 0px !important;
}

.Member-check {
  display: table;
  margin: 0 auto 15px auto;
}

.Member-check p {
  display: inline-block;
  margin-right: 15px;
}

.Member-check-gov {
  margin: 0 auto 5px auto;
}

.Member-check-gov p {
  display: inline-block;
  margin-right: 15px;
}

.green-btn {
  background: #2db67e;
  color: #fff;
  padding: 5px 15px;
  display: table;
  margin: 10px auto;
}

.green-btn:hover {
  background: #818081;
  color: #fff;
}

.same-person {
  display: inline-block !important;
  padding-top: 10px;
}

.same-person p {
  display: initial;
  padding-right: 15px;
}

.same-person input,
.same-person label {
  width: auto !important;
  display: inline-block !important;
  margin-left: 10px;
  margin-right: 5px;
}

.add-btn {
  background: #2db67e;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  border-radius: 3px;
  padding: 6px 10px;
  color: #ffffff !important;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.add-btn:hover {
  background: #818081;
  color: #fff;
  text-decoration: none;
}

@media (min-width: 768px) {}